<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            v-if="isMobile()"
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-space_between mb-1 mb-md-0"
          >
            <div class="bulk_selection d-flex">
              <div class="action-left pt-50">
                <b-form-checkbox
                  :checked="selectConfirmedBookingCheckbox"
                  :indeterminate="isSelectConfirmedBookingCheckboxIndeterminate"
                  @change="selectAllCheckboxUpdate"
                >
                  Select All
                </b-form-checkbox>
              </div>
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mx-1"
                right
                text="Bulk Actions"
                variant="primary"
                :disabled="!selectedBookings.length"
              >
                <b-dropdown-item @click="downloadBulkCSV">
                  Download CSV
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canViewThisAction('update', 'Booking')"
                  v-b-modal.booking-confirmed-modal-bulk-update-status
                >
                  Update Status
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canViewThisAction('delete', 'Booking')"
                  @click="deleteBulkBookings"
                >
                  Delete Booking(s)
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <span class="text-muted">Showing <br>{{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <b-col
            v-else
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="action-left pt-50">
              <b-form-checkbox
                :checked="selectConfirmedBookingCheckbox"
                :indeterminate="isSelectConfirmedBookingCheckboxIndeterminate"
                @change="selectAllCheckboxUpdate"
              >
                Select All
              </b-form-checkbox>
            </div>
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mx-1"
              right
              text="Bulk Actions"
              variant="primary"
              :disabled="!selectedBookings.length"
            >
              <b-dropdown-item @click="downloadBulkCSV">
                Download CSV
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('update', 'Booking')"
                v-b-modal.booking-confirmed-modal-bulk-update-status
              >
                Update Status
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('delete', 'Booking')"
                @click="deleteBulkBookings"
              >
                Delete Booking(s)
              </b-dropdown-item>
            </b-dropdown>
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-form-confirmed-booking"
                ref="filter_dropdown_confirmed_booking"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <booking-dropdown-filters
                  :status-filter.sync="statusFilter"
                  :start-date.sync="startDateFilter"
                  :activity-type.sync="activityTypeFilter"
                  :status-options="statusOptions"
                  :activity-type-options="activityTypeOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="activityTypeFilter.length || startDateFilter || statusFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-for="activityTypeVal in activityTypeFilter"
                  :key="activityTypeVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromActivityTypeFilter(activityTypeVal)"
                  >
                    <span class="text-capitalize">{{ resolveActivityTypeName(activityTypeVal) }}</span>
                  </b-form-tag>
                </li>

                <li
                  v-if="startDateFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="startDateFilter = ''"
                  >
                    {{ startDateFilter }}
                  </b-form-tag>
                </li>
                <li
                  v-for="statusVal in statusFilter"
                  :key="statusVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromStatusFilter(statusVal)"
                  >
                    <span class="text-capitalize">{{ statusVal.replace("-", " ") }}</span>
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refConfirmedBookingsTable"
        class="position-relative min-height-300"
        :items="fetchBookings"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(stringID)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="selectedBookings.includes(data.item._id)"
              @change="toggleSelectedBookings(data.item._id)"
              @click.native.stop
            >
              <b-button
                v-if="canViewThisAction('show', 'Booking')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black align-middle detail-view-id"
                :to="{ name: 'appointment-bookings-show', params: { id: data.item._id } }"
              >
                {{ data.item.stringID }}
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black align-middle detail-view-id not-button"
              >
                {{ data.item.stringID }}
              </b-button>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(start)="data">
          <div
            v-if="data.item.timeSlotApplicable === false"
            class="text-nowrap"
          >
            <span>-</span>
          </div>
          <div
            v-else
            class="text-nowrap"
          >
            <span>{{ dateFormat(data.item.start) }}</span>
            <div v-if="data.item.start != data.item.end">
              <span>{{ timeFormatFromDateString(data.item.start) }}</span> - <span>{{ timeFormatFromDateString(data.item.end) }}</span>
            </div>
          </div>
        </template>

        <template #cell(activityType)="data">
          <b-badge
            :variant="`${data.item.activityType.color}`"
            class="small-badge-button small-badge-button-activity-list"
            :style="{ '--bg-color': data.item.activityType.color }"
          >
            <span v-if="data.item.eventID">
              {{ data.item.eventID ? data.item.eventID.name : '' }}
            </span>
            <span v-else>
              {{ data.item.activityType ? data.item.activityType.typeName : '' }}
            </span>
          </b-badge>
        </template>

        <template #cell(customerName)="data">
          <div class="text-nowrap">
            <span>{{ data.item.customerName }}</span>
            <br>
            <span>{{ data.item.customerContact ? data.item.customerContact : data.item.customerEmail }}</span>
          </div>
        </template>

        <template #cell(agentName)="data">
          <div class="text-nowrap">
            <span>{{ data.item.agentName }}</span>
            <br>
            <span>{{ data.item.agentCode }}</span>
          </div>
        </template>

        <template #cell(createdBy)="data">
          <div class="text-nowrap">
            <span>{{ data.item.createdBy.name }}</span>
            <br>
            <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveBookingStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status.replace("-", " ") }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('show', 'Booking') || canViewThisAction('update', 'Booking') || canViewThisAction('delete', 'Booking')"
          #cell(actions)="data"
        >
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('show', 'Booking')"
              v-b-tooltip.hover.bottom="'Resend Confirmation Message'"
              variant="flat-primary"
              class="send-sms-icon"
              @click="sendSMSAgain(data.item._id)"
            >
              <feather-icon
                icon="SendIcon"
                class=""
                size="18"
              />
            </b-button>
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-if="canViewThisAction('show', 'Booking')"
                :to="{ name: 'appointment-bookings-show', params: { id: data.item._id } }"
              >
                <feather-icon icon="EyeIcon" />
                <span class="align-middle ml-50">View Booking</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="canViewThisAction('update', 'Booking')"
                :to="{ name: 'appointment-bookings-edit', params: { id: data.item._id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit Booking</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="canViewThisAction('delete', 'Booking')"
                @click="deleteEntity(data.item._id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete Booking</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="canViewThisAction('update', 'Booking')"
                id="toggle-btn"
                v-b-modal.booking-confirmed-modal-update-status
                @click="setBooking(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Update Status</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('update', 'Booking')"
                id="toggle-btn"
                v-b-modal.booking-confirmed-modal-update-payment-remarks
                @click="setBooking(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Update Payment Remarks</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('update', 'Booking')"
                @click="copyConfirmationMessage(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Copy Confirmation Message</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalBookings > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalBookings"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="booking-confirmed-modal-update-status"
      ref="update-confirmed-status-modal"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="confirmedBookingStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="changeStatusDependents"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>

          <b-form-checkbox
            v-model="confirmCustomerVal"
            value="accepted"
            unchecked-value=""
            class="custom-control-primary"
            :disabled="status !== 'confirmed' && status !== 'cancelled'"
          >
            <span
              :class="{ 'faded-text': (status !== 'confirmed' && status !== 'cancelled') }"
            >
              Send customer an SMS/Email
            </span>
          </b-form-checkbox>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="booking-confirmed-modal-bulk-update-status"
      ref="confirmed-update-bulk-status-modal"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="updateBulkStatus"
    >
      <b-form @submit.prevent="updateBulkStatus">
        <validation-observer
          ref="confirmedBookingBulkStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-bulk-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="bulkStatus"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="changeBulkStatusDependents"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkStatusValidation"
                class="text-danger"
              >
                {{ bulkStatusError }}
              </small>
            </validation-provider>
          </b-form-group>

          <b-form-checkbox
            v-model="bulkConfirmCustomerVal"
            value="accepted"
            unchecked-value=""
            class="custom-control-primary"
            :disabled="bulkStatus !== 'confirmed' && bulkStatus !== 'cancelled'"
          >
            <span
              :class="{ 'faded-text': (bulkStatus !== 'confirmed' && bulkStatus !== 'cancelled') }"
            >
              Send customer an SMS/Email
            </span>
          </b-form-checkbox>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="booking-confirmed-modal-update-payment-remarks"
      ref="update-confirmed-payment-remarks-modal"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitPaymentRemarksForm"
    >
      <b-form @submit.prevent="submitPaymentRemarksForm">
        <validation-observer
          ref="confirmedBookingPaymentRemarksEditForm"
        >
          <b-form-group
            label="Payment Remarks*"
            label-for="h-event-PaymentRemarks"
          >
            <validation-provider
              #default="{ errors }"
              name="Payment Remarks"
              vid="PaymentRemarks"
              rules="required"
            >
              <b-form-textarea
                id="event-paymentRemarks"
                v-model="paymentRemarks"
                placeholder="Payment Remarks"
                rows="3"
                name="paymentRemarks"
                @input="paymentRemarksValidation == true ? paymentRemarksValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="paymentRemarksValidation"
                class="text-danger"
              >
                {{ paymentRemarksError }}
              </small>
            </validation-provider>
          </b-form-group>

          <b-form-checkbox
            v-model="confirmVal"
            value="accepted"
            unchecked-value=""
            class="custom-control-primary"
          >
            Update status to Confirmed (for Pending Payment bookings)
          </b-form-checkbox>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  ref, onUnmounted, computed,
  // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormInput, BTable, BFormCheckbox, BInputGroup, BInputGroupAppend, BFormTag,
  BBadge, BDropdown, BDropdownItem, BPagination, BForm, BFormGroup, BFormTextarea, BButton, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import store from '@/store'

import useConfirmedList from './useConfirmedList'
import bookingStoreModule from '../bookingStoreModule'
import BookingDropdownFilters from '../BookingDropdownFilters.vue'

export default {
  components: {
    // UserListAddNew,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BButton,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormTextarea,
    BFormCheckbox,
    BFormTag,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,

    BookingDropdownFilters,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
      bookingID: '',
      stringID: '',
      status: 'confirmed',
      bulkStatus: 'confirmed',
      confirmVal: 'accepted',
      confirmCustomerVal: '',
      bulkConfirmCustomerVal: '',
      statusValidation: false,
      statusError: 'Valid status is required',
      bulkStatusValidation: false,
      bulkStatusError: 'Valid status is required',
      paymentRemarks: '',
      paymentRemarksValidation: false,
      paymentRemarksError: 'Valid payment remarks is required',
      statusDataOptions: [
        { title: 'Confirmed', code: 'confirmed' },
        { title: 'Draft', code: 'draft' },
        { title: 'Pending Payment', code: 'pending-payment' },
        { title: 'Pending Approval', code: 'pending-approval' },
        { title: 'Waiting List', code: 'waiting-list' },
        { title: 'Cancelled', code: 'cancelled' },
      ],
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,
      activityTypeOptions: [],

      required,
    }
  },
  beforeMount() {
    this.$http.get('appointment/activity-types/respond-with/name-and-id')
      .then(response => {
        this.activityTypeOptions = response.data.activityTypes ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_confirmed_booking.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_confirmed_booking.show(true)
    },
    removeFromStatusFilter(item) {
      const index = this.statusFilter.indexOf(item)
      if (index > -1) {
        this.statusFilter.splice(index, 1)
      }
    },

    removeFromActivityTypeFilter(item) {
      const index = this.activityTypeFilter.indexOf(item)
      if (index > -1) {
        this.activityTypeFilter.splice(index, 1)
      }
    },

    clearAllFilters() {
      this.startDateFilter = ''
      this.activityTypeFilter = []
      this.statusFilter = []
    },
    resolveActivityTypeName(id) {
      const activity = this.activityTypeOptions.find(o => o._id === id)
      if (activity) {
        return activity.typeName || ''
      }
      return ''
    },
    copyConfirmationMessage(item) {
      const timezone = process.env.VUE_APP_TIME_ZONE
      const toNotShowDurationAT = process.env.VUE_APP_NO_DURATION_AT.split(',') || []
      let date = ''
      let hours = ''
      if (item.start !== item.end) {
        date = moment(item.start).utcOffset(timezone).format('DD MMM YYYY, hh:mm A')
        const duration = moment.duration(moment(item.end).diff(moment(item.start)))
        // hours = ` ${duration.asHours()}hr`
        if (!toNotShowDurationAT.includes(`${item.activityType._id}`)) {
          if (duration.asHours() > 1) {
            hours = ` ${duration.asHours()} hrs`
          } else {
            hours = ` ${duration.asHours()} hr`
          }
        }
      } else {
        date = moment(item.start).utcOffset(timezone).format('DD MMM YYYY')
      }
      let activityType = ''
      if (item.eventID && item.packageID) {
        activityType = `${item.eventID.name} - ${item.packageID.name}`
      } else if (item.eventID) {
        activityType = item.eventID.name
      } else {
        activityType = item.activityType.typeName
      }
      const status = item.status.replace('-', ' ')
      const statusArray = status.split(' ')
      // eslint-disable-next-line no-unused-vars
      let formattedStatus = ''
      statusArray.forEach((element, key) => {
        if (key === (statusArray.length - 1)) {
          formattedStatus += element.charAt(0).toUpperCase() + element.slice(1)
        } else {
          formattedStatus += `${element.charAt(0).toUpperCase() + element.slice(1)} `
        }
      })
      let agentInfo = ''
      if (item.agentName) {
        agentInfo += `${item.agentName}, `
      }
      if (item.agentCode) {
        agentInfo += `${item.agentCode}, `
      }
      const message = `Booking Confirmation: ${activityType ?? ''}${hours} - ${date}
${item.customerName}, ${item.customerContact || item.customerEmail}, ${agentInfo}[${formattedStatus}]`
      this.$copyText(message).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Message has been copied',
            icon: 'BellIcon',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
    setBooking(booking) {
      this.bookingID = booking._id
      this.status = booking.status
      this.stringID = booking.stringID
      this.paymentRemarks = booking.paymentRemarks
    },
    changeStatusDependents() {
      this.statusValidation = false
      if (!(this.status === 'confirmed' || this.status === 'cancelled')) {
        this.confirmCustomerVal = ''
      }
    },
    changeBulkStatusDependents() {
      this.bulkStatusValidation = false
      if (!(this.bulkStatus === 'confirmed' || this.bulkStatus === 'cancelled')) {
        this.bulkConfirmCustomerVal = ''
      }
    },
    downloadBulkCSV() {
      const formData = new FormData()
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.selectedBookings.length; i++) {
        formData.append('bookings[]', this.selectedBookings[i])
      }

      this.$http.post('appointment/bookings/action/bulk/download/csv', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.selectedBookings = []
          // this.$swal({
          //   title: 'Bookings!',
          //   html: 'Selected bookings has been deleted',
          //   // eslint-disable-next-line global-require
          //   imageUrl: require('@/assets/images/icons/save.png'),
          //   imageWidth: 80,
          //   imageHeight: 80,
          //   imageAlt: 'Custom Icon',
          //   showCancelButton: false,
          //   confirmButtonText: 'Okay',
          //   customClass: {
          //     confirmButton: 'btn btn-primary',
          //   },
          //   buttonsStyling: false,
          // })
          const anchor = document.createElement('a')
          anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(response.data)}`
          anchor.target = '_blank'
          anchor.download = 'bookings.csv'
          anchor.click()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateBulkStatus() {
      this.$refs.confirmedBookingBulkStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.bulkStatus)
          formData.append('confirmCustomerVal', this.bulkConfirmCustomerVal)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selectedBookings.length; i++) {
            formData.append('bookings[]', this.selectedBookings[i])
          }

          this.$http.patch('appointment/bookings/action/bulk/status/update', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              this.$emit('emitCountBooking')
              this.bulkConfirmCustomerVal = ''
              this.$swal({
                title: 'Status Updated!',
                html: 'The status for selected bookings has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'bulkStatus') {
                    this.bulkStatusError = validationError.msg
                    this.bulkStatusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteBulkBookings() {
      this.$swal({
        title: 'Are you sure?',
        html: 'This will <strong>delete</strong> selected booking(s). This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.selectedBookings.length; i++) {
              formData.append('bookings[]', this.selectedBookings[i])
            }

            this.$http.patch('appointment/bookings/action/bulk/delete', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.refetchData()
                this.$emit('emitCountBooking')
                this.confirmCustomerVal = ''
                this.selectedBookings = []
                // this.$nextTick(() => {
                //   this.$refs['update-confirmed-status-modal'].toggle('#toggle-btn')
                // })
                this.$swal({
                  title: 'Bookings Deleted!',
                  html: 'Selected bookings has been deleted',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    submitForm() {
      this.$refs.confirmedBookingStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)
          formData.append('confirmCustomerVal', this.confirmCustomerVal)

          this.$http.patch(`appointment/bookings/${this.bookingID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              this.$emit('emitCountBooking')
              this.confirmCustomerVal = ''
              // this.$nextTick(() => {
              //   this.$refs['update-confirmed-status-modal'].toggle('#toggle-btn')
              // })
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },

    submitPaymentRemarksForm() {
      this.$refs.confirmedBookingPaymentRemarksEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('paymentRemarks', this.paymentRemarks)
          formData.append('confirmVal', this.confirmVal)

          this.$http.patch(`appointment/bookings/${this.bookingID}/payment-remarks/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              this.$emit('emitCountBooking')
              // this.$nextTick(() => {
              //   this.$refs['update-confirmed-status-modal'].toggle('#toggle-btn')
              // })
              this.$swal({
                title: 'Payment Remarks Updated!',
                html: `The Payment Remarks for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'paymentRemarks') {
                    this.paymentRemarksError = validationError.msg
                    this.paymentRemarksValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    sendSMSAgain(id) {
      this.$http.get(`appointment/bookings/${id}/send-confirmation-message`)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Selecting Delete will <strong>permanently delete</strong> this item. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deleteBooking()
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const fetchedBookings = ref([])
    const BOOKING_CONFIRMED_APP_STORE_MODULE_NAME = 'app-confirmed-bookings'

    // Register module
    if (!store.hasModule(BOOKING_CONFIRMED_APP_STORE_MODULE_NAME)) store.registerModule(BOOKING_CONFIRMED_APP_STORE_MODULE_NAME, bookingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BOOKING_CONFIRMED_APP_STORE_MODULE_NAME)) store.unregisterModule(BOOKING_CONFIRMED_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Confirmed', value: 'confirmed' },
      { label: 'Pending Payment', value: 'pending-payment' },
      { label: 'Pending Approval', value: 'pending-approval' },
      { label: 'Waiting List', value: 'waiting-list' },
      { label: 'Draft', value: 'draft' },
      { label: 'Cancelled', value: 'cancelled' },
    ]

    const { canViewThisAction } = useAclUtils()

    const {
      fetchBookings,
      tableColumns,
      perPage,
      currentPage,
      totalBookings,
      bookingData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refConfirmedBookingsTable,
      refetchData,

      toDeletedID,
      deleteBooking,

      // UI
      resolveBookingStatusVariant,

      // Extra Filters
      statusFilter,
      activityTypeFilter,
      startDateFilter,
    } = useConfirmedList()

    fetchedBookings.value = bookingData

    const selectedBookings = ref([])
    const toggleSelectedBookings = bookingId => {
      const bookingIndex = selectedBookings.value.indexOf(bookingId)

      if (bookingIndex === -1) selectedBookings.value.push(bookingId)
      else selectedBookings.value.splice(bookingIndex, 1)
    }
    const selectConfirmedBookingCheckbox = computed(() => fetchedBookings.value.value.length && (fetchedBookings.value.value.length === selectedBookings.value.length))
    const isSelectConfirmedBookingCheckboxIndeterminate = computed(() => Boolean(selectedBookings.value.length) && fetchedBookings.value.value.length !== selectedBookings.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedBookings.value = val ? fetchedBookings.value.value.map(book => book._id) : []
    }

    return {

      // Sidebar

      fetchBookings,
      tableColumns,
      perPage,
      currentPage,
      totalBookings,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refConfirmedBookingsTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteBooking,
      fetchedBookings,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveBookingStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
      activityTypeFilter,
      startDateFilter,

      // Mail Selection
      selectConfirmedBookingCheckbox,
      isSelectConfirmedBookingCheckboxIndeterminate,
      selectedBookings,
      toggleSelectedBookings,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  .faded-text {
    color: #EFEFEF;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
